<template>
  <a
    class="filter-container p-3 mt-3"
    href="javascript:void(0);"
    @click="searchBySpotType(title)"
  >
    <div class="filter p-2">
      <div class="filter-title text-uppercase align-middle">
        <i :class="icon" class="icon px-2" />
        {{ this.title }} Spots
      </div>
    </div>
  </a>
</template>

<script>
export default {
  name: "TenantSearchFilterItem",
  props: {
    icon: String,
    title: String,
    spotTypes: Array
  },
  methods: {
    setSelectedSpotTypes(spotTypes) {
      this.$store.commit("search/setSelectedSpotTypes", [...spotTypes]);
    },
    searchBySpotType(spotTypeName) {
      const spotTypeId = this.getSpotTypeId(spotTypeName);
      this.setSelectedSpotTypes([spotTypeId]);
      this.$router.push("/parksearchresults").catch(() => {});
    },
    getSpotTypeId(spotTypeName) {
      const spotType = this.spotTypes.find(
        x => x.name.toLowerCase() === spotTypeName.toLowerCase()
      );
      return spotType.id;
    }
  }
};
</script>

<style scoped>
img {
  max-height: 50px;
}
.filter-container {
  background: linear-gradient(
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.5)
  );
  cursor: pointer;
  text-decoration: none;
  display: block;
}
.filter {
  background: linear-gradient(
    rgba(255, 255, 255, 0.7),
    rgba(255, 255, 255, 0.7)
  );
}
.filter > img {
  max-width: 25%;
}
.filter-title {
  display: inline;
  font-weight: 800;
  font-size: x-large;
  color: #757575;
}

.icon {
  vertical-align: middle;
}

@media (max-width: 800px) {
  .filter-title {
    font-size: large;
  }
}

@media (max-width: 350px) {
  .filter > img {
    max-width: 15%;
  }
}
</style>
