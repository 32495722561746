<template>
  <div
    class="d-flex align-items-center search-background"
    :style="searchBackground"
  >
    <div class="container text-center">
      <h2 class="text-uppercase text-center text-white">
        {{ tenant.tagline }}
      </h2>
      <div class="search-bar-container">
        <form @submit.prevent="warnAndRoute" class="p-3">
          <div class="form-row align-items-center m-0">
            <ParkSearch
              mode="primary"
              :includeMapBox="true"
              :includeParks="true"
              placeholder="Search for a park, campground, or places near you..."
              @latLongUpdated="latLongUpdated"
            />
          </div>
        </form>
      </div>
      <button
        type="button"
        class="btn btn-primary btn-lg mt-3"
        @click="navigateToResults"
      >
        View All Parks and Search by Map
      </button>
    </div>
  </div>
</template>

<script>
import ParkSearch from "@/components/search/ParkSearch.vue";
export default {
  name: "TenantSearch",
  components: {
    ParkSearch
  },
  computed: {
    tenant() {
      return this.$store.getters["tenant/tenantInfo"];
    },
    searchBackground() {
      const image =
        this.tenant.bannerImageUrl === "" ||
        this.tenant.bannerImageUrl === "n/a"
          ? require("@/assets/images/tenant/ReservationsHomepage_SearchBanner.jpg")
          : this.tenant.bannerImageUrl;
      return {
        background:
          "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(" +
          image +
          ")"
      };
    }
  },
  methods: {
    latLongUpdated() {
      this.navigateToResults();
    },
    warnAndRoute() {
      this.navigateToResults();
      this.$store.commit("alert/setErrorAlert", {
        type: "alert-info",
        message:
          "No parks matched your search criteria. Here are parks you may like instead.",
        layer: "searchList"
      });
    },
    navigateToResults() {
      this.$router.push("/parksearchresults").catch(() => {});
    }
  }
};
</script>

<style scoped>
.search-background {
  height: 500px;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  border-bottom: 5px solid #1078a8;
}
#searchBar {
  border-radius: 0%;
}
.search-bar-container {
  width: 100%;
  background: linear-gradient(
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.5)
  );
}
h2 {
  font-size: xxx-large;
  font-weight: bold;
  text-shadow: 0px 0px 1rem #000000;
}

@media (max-width: 500px) {
  h2 {
    font-size: xx-large;
  }
}
</style>
