<template>
  <main>
    <TenantAlert />
    <TenantSearch />
    <TenantSearchFilters />
    <TenantFeaturedLocations />
  </main>
</template>

<script>
import TenantSearch from "@/components/home/TenantSearch.vue";
import TenantSearchFilters from "@/components/home/TenantSearchFilters.vue";
import TenantFeaturedLocations from "@/components/home/TenantFeaturedLocations.vue";
import TenantAlert from "@/components/alert/TenantAlert.vue";

export default {
  name: "Home",
  title: "Home",
  components: {
    TenantSearch,
    TenantSearchFilters,
    TenantFeaturedLocations,
    TenantAlert
  },
  mounted() {
    this.$store.dispatch("search/clear");
    this.$store.dispatch("transaction/resetSpotReservation", false);
    this.$store.commit("auth/setLoading", false);
  },
  beforeCreate() {
    this.$store.commit("auth/setLoading", false);
    this.$store.dispatch("search/clear");
  }
};
</script>
