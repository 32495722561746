<template>
  <div
    class="d-flex align-items-center filter-background"
    :style="{
      background:
        'linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(' +
        tenant.searchBackgroundImageUrl +
        ')'
    }"
  >
    <div class="container mb-4 mt-2">
      <div class="row m-0">
        <div class="col col-12">
          <h2
            class="text-uppercase text-center text-white mt-3 mb-2 align-middle"
          >
            Find parks by Spot Type
          </h2>
        </div>
      </div>
      <div class="row m-0">
        <div class="col-12 col-sm-12 col-md-6">
          <TenantSearchFilterItem
            v-for="type in leftSpotTypes"
            :key="type.id"
            :icon="type.icon"
            :title="type.name"
            :spotTypes="spotTypes"
          />
        </div>
        <div class="col-12 col-sm-12 col-md-6">
          <TenantSearchFilterItem
            v-for="type in rightSpotTypes"
            :key="type.id"
            :icon="type.icon"
            :title="type.name"
            :spotTypes="spotTypes"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TenantSearchFilterItem from "@/components/home/TenantSearchFilterItem.vue";
import ParkSearchService from "@/services/ParkSearchService.js";

export default {
  name: "TenantSearchFilters",
  components: {
    TenantSearchFilterItem
  },
  data() {
    return {
      spotTypes: []
    };
  },
  computed: {
    tenant() {
      return this.$store.getters["tenant/tenantInfo"];
    },
    columnBreak() {
      var half = Math.floor(this.spotTypes.length / 2);
      var remainder = this.spotTypes.length % 2;
      return remainder != 0 ? half + remainder : half;
    },
    rightSpotTypes() {
      return this.spotTypes.slice(this.columnBreak);
    },
    leftSpotTypes() {
      return this.spotTypes.slice(0, this.columnBreak);
    }
  },
  methods: {
    async getSpotTypes() {
      const tenantId = this.$store.getters["tenant/tenantId"];
      var parkSearchService = new ParkSearchService(null, null, tenantId);
      this.spotTypes = await parkSearchService.getSpotTypes();
    }
  },
  mounted() {
    this.getSpotTypes();
  }
};
</script>

<style scoped>
.filter-background {
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  border-bottom: 5px solid #1078a8;
}
h2 {
  font-size: xxx-large;
  font-weight: bold;
  text-shadow: 0px 0px 1rem #000000;
}

@media (max-width: 500px) {
  h2 {
    font-size: xx-large;
  }
}
</style>
