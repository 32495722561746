<template>
  <div v-if="featuredParks.length > 0" class="container pt-3">
    <h2 class="text-uppercase text-center pb-4">Featured Destinations</h2>
    <div class="justify-content-center">
      <div
        class="row mb-5 featured-destination no-gutters"
        v-for="park in featuredParks"
        :key="park.id"
      >
        <div class="col-md-4">
          <a @click="$router.push(`/location/${park.id}`).catch(() => {})">
            <img
              :src="park.featuredImageUrl"
              :alt="park.longName + ' Landscape'"
            />
          </a>
        </div>
        <div class="col-md-8 p-3 d-flex align-items-start flex-column">
          <div class="mb-auto w-100">
            <h3 class="card-title">
              <a
                @click="$router.push(`/location/${park.id}`).catch(() => {})"
                >{{ park.longName }}</a
              >
            </h3>
            <p
              class="card-text"
              style="max-height: 150px; overflow-y: auto;"
              v-html="park.featuredDescription"
            ></p>
          </div>
          <div class="mt-2">
            <a
              @click="$router.push(`/location/${park.id}`).catch(() => {})"
              class="btn btn-primary text-white"
              href="javascript:void(0);"
              >View Park Details</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LocationApi from "@/generatedapiclients/src/api/LocationApi.js";
export default {
  name: "TenantLocations",
  data() {
    return {
      featuredParks: []
    };
  },
  computed: {
    tenant() {
      return this.$store.getters["tenant/tenantInfo"];
    }
  },
  methods: {
    async getFeaturedLocations() {
      const api = new LocationApi();
      const featuredResp = await api.v1TenantTenantIdLocationFeaturedGet(
        this.tenant.id
      );
      featuredResp.forEach(x => {
        if (x.featuredImageUrl == null || x.featuredImageUrl === "") {
          x.featuredImageUrl = this.tenant.defaultLocationImageUrl;
        }
      });
      this.featuredParks = featuredResp;
    }
  },
  mounted() {
    this.getFeaturedLocations();
  }
};
</script>

<style scoped>
img {
  object-fit: cover;
  border-style: solid;
  border-color: #1078a8;
  height: 250px;
  width: 100%;
}
h2 {
  font-size: xxx-large;
  font-weight: bold;
  color: #696868;
}
.card-title {
  color: #1078a8;
  font-weight: bold;
}
.featured-destination {
  background-color: white;
  width: 100%;
}

@media (max-width: 500px) {
  h2 {
    font-size: xx-large;
  }
}

.link {
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}
</style>
